<template>
  <b-overlay :show="loading">
    <b-row class="match-height">
      <b-col lg="9">
        <bienvenida-corona
           v-bind:date_end="subcripcion.period_date_end"
           v-bind:date_ini="subcripcion.period_date_initial"
        />
      </b-col>
      <b-col lg="3">
        <b-card>
          <div>
            <b-card-title class="mb-sm-0 mb-1">
              Suscripciones
            </b-card-title>
          </div>

          <div>
            <b-row>
              <b-col
                  sm="12"
                  class="d-flex justify-content-center"
              >

                <!-- apex chart -->
                <vue-apex-charts
                    type="radialBar"
                    height="270"
                    :options="supportTrackerRadialBar.chartOptions"
                    :series="resumenSuscripciones.ocupadasXCiento"
                />
              </b-col>
              <!--/ chart -->
            </b-row>
          </div>

          <b-row class="text-center mx-0">
            <b-col
                cols="6"
                class="border-top border-right d-flex align-items-between pt-1 pr-0 pl-0 flex-column"
            >
              <b-card-text class="text-muted mb-0">
                Total
              </b-card-text>
              <h4 class="font-weight-bolder mb-0">
                {{ resumenSuscripciones.total }}
              </h4>
            </b-col>
            <b-col
                cols="6"
                class="border-top d-flex align-items-between pt-1 pr-0 pl-0 flex-column"
            >
              <b-card-text class="text-muted mb-0">
                Ocupadas
              </b-card-text>
              <h4 class="font-weight-bolder mb-0">
                {{ resumenSuscripciones.ocupadas }}
              </h4>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
      <b-col lg="9">
        <b-card class="mb-2">
          <h4 class="card-title">
            Resumen de Cursos
          </h4>
          <b-row class="pl-1 mb-1">
            <b-col
                cols="8"
            >
              <div v-if="totalRows > 0">
                <span class="text-muted">  Mostrando del {{ from }} al {{ perPage }} de {{ totalRows }} filas</span>
              </div>
            </b-col>
            <b-col
                cols="4"
            >
              <div
                  v-if="totalRows > 0"
                  class="text-right mr-1"
              >
                <b-link href="/cursos-corona">
                  Ver más
                </b-link>
              </div>
            </b-col>
          </b-row>
          <b-col
              cols="12"
          >
            <b-table
                id="my-table"
                striped
                hover
                small
                :per-page="perPage"
                :items="cursos"
                :fields="fields"
                responsive
                class="mb-0"
                show-empty
                :busy="loadingTable"
                :sort-by="sortBy"
                :sort-desc="sortDesc"
                :sort-direction="sortDirection"
                @sort-changed="sortChanged"
            >
              <template #table-busy>
                <div class="text-center text-primary my-2">
                  <b-spinner class="align-middle mr-1" />
                  <strong>Loading...</strong>
                </div>
              </template>
              <template #cell()="data">
                {{ data.value ? data.value : "-" }}
              </template>
              <template #cell(porciento_aprobado)="data">
                <b-badge :variant="porciento_aprobados(data.item.porciento_aprobado)">
                  {{ data.item.porciento_aprobado }}
                </b-badge>
              </template>
              <template #cell(total_alumnos)="data">
                <b-badge variant="light-info">
                  {{ data.item.total_alumnos }}
                </b-badge>
              </template>
              <template #cell(alumnos_aprobados)="data">
                <b-badge variant="light-success">
                  {{ data.item.alumnos_aprobados }}
                </b-badge>
              </template>
              <template #cell(alumnos_reprobados)="data">
                <b-badge variant="light-danger">
                  {{ data.item.alumnos_reprobados }}
                </b-badge>
              </template>
              <template #cell(alumnos_sin_evaluacion_final)="data">
                <b-badge variant="light-primary">
                  {{ data.item.alumnos_sin_evaluacion_final }}
                </b-badge>
              </template>
              <template #cell(alumnos_curso_no_iniciado)="data">
                <b-badge variant="light-danger">
                  {{ data.item.alumnos_curso_no_iniciado }}
                </b-badge>
              </template>
              <template #empty="scope">
                <div class="text-center">
                  No existen resultados
                </div>
              </template>
            </b-table>

          </b-col>
        </b-card>
      </b-col>
      <b-col lg="3">
        <b-card>
          <h4 class="mb-0 mb-4">
            % Aprobados
          </h4>

          <!-- apex chart -->
          <vue-apex-charts
              type="radialBar"
              height="245"
              class="my-2"
              :options="goalOverviewRadialBar"
              :series="porcientoAprobados.porcientoAprobado"
          />
          <b-row class="text-center mx-0 mt-1">
            <b-col
                cols="12"
                class="border-top d-flex align-items-between flex-column pr-0 pl-0 py-1"
            >
              <b-card-text class="text-muted mb-0 pr-0 pl-0">
                Total de Cursos
              </b-card-text>
              <h3 class="font-weight-bolder mb-0">
                {{ porcientoAprobados.totalCursos }}
              </h3>

            </b-col>

            <!-- <b-col
               cols="6"
               class="border-top d-flex align-items-between flex-column py-1 pr-0 pl-0"
             >
               <b-card-text class="text-muted mb-0 mr-0">
                 Aprobados
               </b-card-text>
               <h3 class="font-weight-bolder mb-0">
                 {{ totalesAlumnos.totalAprobados }}
               </h3>
             </b-col>-->
          </b-row>
        </b-card>
      </b-col>

    </b-row>
  </b-overlay>
</template>

<script>
import {
  BCard, BCardTitle, BCardText, BTable, BBadge, BLink, BSpinner
} from "bootstrap-vue";
import Bienvenida from "@/components/dashboard/Bienvenida.vue";
import VueApexCharts from "vue-apexcharts";
import { kFormatter } from "@core/utils/filter";
import { $themeColors } from "@themeConfig";
import BienvenidaCorona from "@/components/dashboard/BienvenidaCorona";

const $successColorShade = "#28dac6";
const $strokeColor = "#ebe9f1";
const $textHeadingColor = "#5e5873";
const $goalStrokeColor2 = "#1a209c";
export default {
  name: "Dashboard",
  components: {
    'bienvenida-corona': BienvenidaCorona,
    VueApexCharts,
    BCard,
    BCardTitle,
    BCardText,
    BTable,
    BBadge,
    BLink,
    BSpinner
  },
  data() {
    return {
      totalAlumnos: [],
      totalAlumnosActivos: [],
      listadoCursos: [],
      porcientoAprobado: [],

      supportTrackerRadialBar: {
        chartOptions: {
          plotOptions: {
            radialBar: {
              size: 150,
              offsetY: 20,
              startAngle: -150,
              endAngle: 150,
              hollow: {
                size: "65%"
              },
              track: {
                background: "#fff",
                strokeWidth: "100%"
              },
              dataLabels: {
                name: {
                  offsetY: -5,
                  color: "#5e5873",
                  fontSize: "1rem"
                },
                value: {
                  offsetY: 15,
                  color: "#5e5873",
                  fontSize: "1.714rem"
                }
              }
            }
          },
          colors: [$themeColors.danger],
          fill: {
            type: "gradient",
            gradient: {
              shade: "dark",
              type: "horizontal",
              shadeIntensity: 0.5,
              gradientToColors: [$themeColors.primary],
              inverseColors: true,
              opacityFrom: 1,
              opacityTo: 1,
              stops: [0, 100]
            }
          },
          stroke: {
            dashArray: 8
          },
          labels: ["Ocupadas"]
        }
      },
      perPage: 5,
      fields: [
        { key: "curso" },
        {
          key: "porciento_aprobado",
          label: "% Aprobados",
          tdClass: "text-center",
          thClass: "text-center",
          sortable: true
        },
        {
          key: "total_alumnos", tdClass: "text-center", thClass: "text-center", sortable: true
        },
        {
          key: "alumnos_aprobados",
          label: "Alumnos aprobados",
          tdClass: "text-center",
          thClass: "text-center",
          sortable: true
        },
        {
          key: "alumnos_reprobados",
          label: "Alumnos reprobados",
          tdClass: "text-center",
          thClass: "text-center",
          sortable: true
        },
        {
          key: "alumnos_sin_evaluacion_final",
          label: "Sin finalizar",
          tdClass: "text-center",
          thClass: "text-center",
          sortable: true
        },
        {
          key: "alumnos_curso_no_iniciado",
          label: "Sin iniciar",
          tdClass: "text-center",
          thClass: "text-center",
          sortable: true
        },

      ],
      radialBarChart: {
        series: [80],
        chartOptions: {
          colors: ["#540096"],
          plotOptions: {
            radialBar: {
              size: 285,
              hollow: {
                size: "55%"
              },
              track: {
                margin: 13
              },
              dataLabels: {
                name: {
                  fontSize: "2rem",
                  fontFamily: "Montserrat"
                },
                value: {
                  fontSize: "1rem",
                  fontFamily: "Montserrat"
                },
                total: {
                  show: true,
                  fontSize: "1rem",
                  label: "Ocupadas",
                  formatter() {
                    return "80%";
                  }
                }
              }
            }
          },
          legend: {
            show: true,
            position: "bottom",
            horizontalAlign: "center"
          },
          stroke: {
            lineCap: "round"
          },
          labels: ["Capacidades"]
        }
      },
      sortBy: '',
      sortDesc: false,
      sortDirection: "asc",
      goalOverviewRadialBar: {
        chart: {
          height: 245,
          type: "radialBar",
          sparkline: {
            enabled: true
          },
          dropShadow: {
            enabled: true,
            blur: 3,
            left: 1,
            top: 1,
            opacity: 0.1
          }
        },
        colors: [$goalStrokeColor2],
        plotOptions: {
          radialBar: {
            offsetY: -10,
            startAngle: -150,
            endAngle: 150,
            hollow: {
              size: "77%"
            },
            track: {
              background: $strokeColor,
              strokeWidth: "50%"
            },
            dataLabels: {
              name: {
                show: false
              },
              value: {
                color: $textHeadingColor,
                fontSize: "2.86rem",
                fontWeight: "600"
              }
            }
          }
        },
        fill: {
          type: "gradient",
          gradient: {
            shade: "dark",
            type: "horizontal",
            shadeIntensity: 0.5,
            gradientToColors: [$themeColors.success],
            inverseColors: true,
            opacityFrom: 1,
            opacityTo: 1,
            stops: [0, 100]
          }
        },
        stroke: {
          lineCap: "round"
        },
        grid: {
          padding: {
            bottom: 30
          }
        }
      },

    };
  },
  computed: {
    loading() {
      return this.$store.state.dashboardCorona.loadingCorona;
    },
    loadingTable() {
      return this.$store.state.curso.loading;
    },
    alumnosTotal() {
      this.totalAlumnos = this.$store.state.dashboardCorona.totalAlumnos
      return this.totalAlumnos
    },
    valueSuscripciones() {
      return  this.$store.state.dashboardCorona.dataSuscripciones

    },
    suscripcion() {
      return  this.$store.state.dashboardCorona.suscripcion

    },
    alumnosActivostotal() {
      this.totalAlumnosActivos = this.$store.state.dashboardCorona.totalAlumnosActivos;
      return this.totalAlumnosActivos;
    },
    porcientoAprobados() {
      this.porcientoAprobado = this.$store.state.dashboardCorona.dataPorciento;
      const porciento = this.porcientoAprobado.porciento_aprobado != null ? this.porcientoAprobado.porciento_aprobado.slice(0, -2) : 0;
      return {
        totalCursos: this.totalRows,
        totalAprobados: 2000,
        porcientoAprobado: [porciento]
      };
    },
    resumenSuscripciones() {
      return {
        total: this.valueSuscripciones.total_alumnos,
        ocupadas: this.valueSuscripciones.ocupados,
        ocupadasXCiento: [this.valueSuscripciones.porciento]
      };
    },
    totalesAlumnos() {
      return {
        total: 2500,
        totalAprobados: 2000,
        series: [80]
      };
    },
    cursos() {
      this.listadoCursos = this.$store.state.curso.list;
      return this.listadoCursos;
    },
    totalRows() {
      return this.$store.state.curso.rowsTotal;
    },
    to() {
      return this.$store.state.curso.toSelect;
    },
    from() {
      return this.$store.state.curso.from;
    },
    lastPage() {
      return this.$store.state.curso.lastPage;
    },
    subcripcion(){
      return this.$store.state.dashboardCorona.suscripcion
    }
  },

  mounted() {
    this.getCursos();
    this.getSubcripcion();
    /*this.getUserMenu().then(() => {
      setTimeout(() => {}, 10)
      this.getCursos()
    })*/
  },
  methods: {
    async getUserMenu() {
      return this.$store.dispatch("auth/getUserMenu");
    },
    getPorcientoAprobado() {
      this.$store.dispatch("dashboardCorona/getPorcientoAprobado");
    },
    getSubcripcion() {
      this.$store.dispatch("dashboardCorona/getSuscripcion");
    },
    getSuscripcionesCursosNormativos() {
      this.$store.dispatch("dashboardCorona/getSuscripcionesCursosNormativos");
    },
    sortChanged(e) {
      this.sortBy = e.sortBy;
      this.sortDesc = e.sortDesc;
      this.getCursos();

    },
    getCursos() {
      const filterData = {
        fechaDesde: this.fechaDesde,
        fechaHasta: this.fechaHasta,
        sortDirection: this.sortDesc === false ? "asc" : "desc",
        sortBy: this.sortBy,
        page: this.page,
        perPage: this.perPage
      };
      this.$store.dispatch("curso/getValue", filterData);
    },
    getTotalAlumnos() {
      this.$store.dispatch("dashboardCorona/getTotalAlumnos");
    },

    porciento_aprobados(porciento1) {
      const cadena = porciento1 != null ? porciento1.slice(0, -2) : 0;
      const porciento = parseInt(cadena);
      if (porciento <= 50) {
        return "light-danger";
      }
      if (porciento > 50 && porciento <= 70) {
        return "light-warning";
      }
      if (porciento > 70) {
        return "light-success";
      }
    },
    kFormatter
  },
  watch: {
    listadoCursos(actual, nuevo) {
      if (actual != nuevo)
        this.getPorcientoAprobado();

    },
    porcientoAprobado(actual, nuevo) {
      if (actual != nuevo)
        this.getSuscripcionesCursosNormativos();
    }
  }

};
</script>
<style lang="scss">
@import '@core/scss/vue/pages/dashboard-ecommerce.scss';
@import '@core/scss/vue/libs/chart-apex.scss';

.apexcharts-legend {
  display: none;
}

.welcome-img {
  top: 70px !important;
}

.apexcharts-theme-light .apexcharts-tracks g path.apexcharts-radialbar-area {
  stroke: rgba(235, 233, 241, 0.35) !important;
}
</style>
